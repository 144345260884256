import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Елітний тенісний досвід
			</title>
			<meta name={"description"} content={"Елітний Тенісний Простір пропонує преміум-оренду суду, обладнання для вищого рівня та спеціалізований тренер для початківців."} />
			<meta property={"og:title"} content={"Головна | Елітний тенісний досвід"} />
			<meta property={"og:description"} content={"Елітний Тенісний Простір пропонує преміум-оренду суду, обладнання для вищого рівня та спеціалізований тренер для початківців."} />
			<link rel={"shortcut icon"} href={"https://dniprodiscovery.live/img/icon321.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://dniprodiscovery.live/img/icon321.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://dniprodiscovery.live/img/icon321.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://dniprodiscovery.live/img/icon321.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://dniprodiscovery.live/img/icon321.png"} />
			<meta name={"msapplication-TileImage"} content={"https://dniprodiscovery.live/img/icon321.png"} />
			<meta name={"msapplication-TileColor"} content={"https://dniprodiscovery.live/img/icon321.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://dniprodiscovery.live/img/court_2.2.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			/>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Elite{" "}
					<br />
					Тенісний
					<br />
					{" "}Простір
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="25%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Елітний Тенісний Простір пропонує преміум-оренду, обладнання для вищого рівня та спеціалізований тренер для початківців. Занурення в унікальний тенісний досвід, призначений для кожного ентузіаста.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-green">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Вивчіть наші пропозиції
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Підписи судів
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Наші корти розроблені як для дозвілля, так і для конкурентних духів.Незалежно від того, чи ви за випадкову гру чи серйозний матч, ми підготували суд для ваших потреб.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
					position="relative"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Оснащення та гра
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Не обладнаний?Не турбуйтеся.Ми пропонуємо різноманітне обладнання для тенісу для тих, хто прагне грати, але, можливо, залишило свою передачу вдома.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Куточок першокласників
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Для тих, хто здійснює дівоче плавання у світ тенісу, наші спеціально навчені тренери тут, щоб направити вас.Переживайте кураторську ініціацію до гри.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="30px 0px 30px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 50px 0px 50px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#ffffff"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Унікальні штрихи, які роблять нас різними
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 40px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 40px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Нічна сова теніс
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Для тих, хто вважає розраду під зоряним небом, наші суди відкриті пізно.Освітлюйте свою гру за допомогою нашого спеціально розробленого нічного освітлення атмосфери.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 40px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 40px 35px"
					position="relative"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Точні зони гри
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Оводіть свою техніку.Певні суди позначені додатковими лініями та зонами, ідеально підходять для гравців, які хочуть практикувати конкретні кадри, служать або ноги.Управляйте навчання за допомогою цілеспрямованого геймплея.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 40px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 40px 35px"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
						Тенісний куточок
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Оновити та заправляти.Вибір закуски та напоїв гурманів чекає на вас після матчу або для тих, хто підбадьорює збоку.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-green" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="0px 0px 624px 0px"
						display="flex"
						flex-direction="column"
						background="url(https://dniprodiscovery.live/img/ball_3.2.jpg) center/cover"
						width="100%"
						lg-padding="0px 0px 248px 0px"
						sm-padding="0px 0px 380px 0px"
					/>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://dniprodiscovery.live/img/friends_2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://dniprodiscovery.live/img/ball_2.2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://dniprodiscovery.live/img/couple_3.2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="20px 0 130px 0"
			md-padding="80px 0 80px 0"
			background="--color-green"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Відгуки
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--light">
							"Елітний тенісний досвід - це більше, ніж просто оренда суду. Це досвід, від гри до атмосфери. Настійно рекомендується!"
						</Text>
					</Box>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--light">
								Андрій М.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--light">
							"Будучи першокласником, я вагався, але тренери тут зробили мою посвячення в теніс безшовно. Не можу дочекатися повернення".
						</Text>
					</Box>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--light">
								Олена К.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="--light">
							"Кожен раз, коли я виходжу на суди в елітному тенісному досвіді, це відчуває себе персоналізованим досвідом. Від їх першого обладнання до унікальних зон точної гри, моя гра піднялася так, як я ніколи не уявляв".
						</Text>
					</Box>
					<Box display="flex" align-items="center" md-min-width={0} md-min-height={0}>
						<Box min-width="10px" min-height="10px">
							<Text margin="0px 0px 7px 0px" font="normal 600 20px/1.5 --fontFamily-sans" color="--light">
								Олексій В.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});